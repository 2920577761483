<template>
   <div class="face">
       <!-- 顶部图标 -->
        <van-nav-bar  >
             <template #left>
                 <van-icon name="cross" size="22" :border="false" @click="close"/>
             </template>
        </van-nav-bar>
         <!-- 内容主体-->
        <center class="box">
             <h1>为保障账户安全</h1>
             <h3>需采集你的人脸信息以核对身份</h3>
             <div class="face_nar"></div>
             <h3> <div class="icon ione"></div> 请正对屏幕并使脸位于取景框内</h3>
             <h3> <div class="icon itwo"></div>请保持光线充足，避免光照过强或过弱</h3>
             <h3> <div class="icon ithree"></div>根据画面提示完成单个或多个动作检测</h3>
             <div class="check">
                 <el-checkbox v-model="checked"></el-checkbox>
                 <p>同意{{GLOBAL}}采集并使用人脸信息用于核实身份。</p>
                 <p> 查看 <i @click="faceProtocol">《 人脸识别服务用户授权书》</i> </p>
             </div>
        </center>
        <div>
            <div :class="{btn:true,btnBg:checked}" @click="submitFace">开始人脸识别</div>
        </div>
        
        <footer>
            <div class="footText">-专业贵金属服务商-</div>
        </footer>

   </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            checked: false,
        }
    },
    methods:{
        // 开始识别按钮
        submitFace(){
            // 用户并未同意人脸协议
            if (!this.checked) return false
            console.log('1',this.$route.query.path);
            // 用户同意
            //  获取Access Token 方法
                this.$router.push({
                    name:'faceing',
                    query:{path:this.$route.query.path}
                })
        },
        // 人脸协议按钮
        faceProtocol(){
                this.$router.push({
                    name:'faceProtocol'
                })
        },
        // 关闭按钮事件
        close(){
            this.$router.push({
                name:'person'
            })
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep{
     .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
        .van-nav-bar__title{
            font-size: 40px;
        }
        .van-icon{
            font-size: 40px;
            color: #1e1e1e;
        }
        }
     }
     .el-checkbox__inner{
         border: 1px solid #565656 !important;
     }
     .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #565656!important;
    border-color: #565656!important;
}
}

.box{
    margin-top: 36px;
    .face_nar{
        margin: 36px auto;
        width: 440px;
        height: 432px;
        background: url("~@/assets/images/face_nor@2xx.png")no-repeat;
        background-size: 100% 100%;
    }
    h1{
    width: 100%;
    text-align: center;
    line-height: 36px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #1A1A1A;
    font-size: 42px;
    }
    h3{
    width: 100%;
    line-height: 36px;
    font-size: 28px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #1A1A1A;
    margin-top: 36px;
    padding-left: 176px;
    position: relative;
    text-align: left;
    .icon{

        position: absolute;
        top: -6px;
        left: 100px;
    }
    .ione{
        width: 50px;
        height: 46px;
        background: url("~@/assets/images/icon_ facing the screen@2x.png")no-repeat;
        background-size: 100% 100%;
    }
    .itwo{
        width: 50px;
        height: 56px;
        background: url("~@/assets/images/icon_enough light@2x.png")no-repeat;
        background-size: 100% 100%;
        left: 98px;
    }
    .ithree{
        width: 50px;
        height: 52px;
        background: url("~@/assets/images/icon_multi action@2x.png")no-repeat;
        background-size: 100% 100%;
    }
    }
}
.check{
    width: 100%;
    margin-top: 80px;
    position: relative;
    p{
        text-align: left;
        padding-left: 176px;
        font-size: 24px;
        i{
            font-style: normal;
            color: #9eb7f2;
        }
    }
}
.el-checkbox{
    position: absolute;
    top: 0;
    left: 130px;
}
.btn{
    width: 500px;
    height: 84px;
    background-color: #e5e5e5;
    border-radius: 100px;
    opacity: 1;
    margin: 60px auto;
    color: #FFFFFF;
    line-height: 84px;
    font-size: 32px;
    text-align: center;
}
.btnBg{
    background-color: #000000 !important;
}
.footText{
    text-align: center;
    color: #e5e5e5;
}
footer{
    width: 100%;
    position: absolute;
    bottom: 40px;
}
</style>