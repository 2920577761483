var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "face" },
    [
      _c("van-nav-bar", {
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function() {
              return [
                _c("van-icon", {
                  attrs: { name: "cross", size: "22", border: false },
                  on: { click: _vm.close }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("center", { staticClass: "box" }, [
        _c("h1", [_vm._v("为保障账户安全")]),
        _c("h3", [_vm._v("需采集你的人脸信息以核对身份")]),
        _c("div", { staticClass: "face_nar" }),
        _c("h3", [
          _c("div", { staticClass: "icon ione" }),
          _vm._v(" 请正对屏幕并使脸位于取景框内")
        ]),
        _c("h3", [
          _c("div", { staticClass: "icon itwo" }),
          _vm._v("请保持光线充足，避免光照过强或过弱")
        ]),
        _c("h3", [
          _c("div", { staticClass: "icon ithree" }),
          _vm._v("根据画面提示完成单个或多个动作检测")
        ]),
        _c(
          "div",
          { staticClass: "check" },
          [
            _c("el-checkbox", {
              model: {
                value: _vm.checked,
                callback: function($$v) {
                  _vm.checked = $$v
                },
                expression: "checked"
              }
            }),
            _c("p", [
              _vm._v(
                "同意" + _vm._s(_vm.GLOBAL) + "采集并使用人脸信息用于核实身份。"
              )
            ]),
            _c("p", [
              _vm._v(" 查看 "),
              _c("i", { on: { click: _vm.faceProtocol } }, [
                _vm._v("《 人脸识别服务用户授权书》")
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", [
        _c(
          "div",
          {
            class: { btn: true, btnBg: _vm.checked },
            on: { click: _vm.submitFace }
          },
          [_vm._v("开始人脸识别")]
        )
      ]),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "footText" }, [_vm._v("-专业贵金属服务商-")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }